import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BIRCHWOOD_API

export const apiService = {
  async getTradelines() {
    return new Promise((resolve) => {
      return axios.get('tradelines').then((r) => {
        setTimeout(() => {
          resolve(r.data)
        }, 10)
      })
    })
  },

  async getAccountNumber() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          accountnumber: '0602001826',
          descriptione: 'Mortgage',
          outstandingBalance: '$173,952.27',
          amountDue: '$1266.2',
          paymentDueDate: '04/25/2018',
          payoffAmount: '$204,419.58',
          interestRate: '6.125',
          term: '360',
          InstallmentAmount: '$1324.54',
          maturityDate: '6/3/2020',
          interestYTD: '$3208.71',
          'interestRateType (Fix/Var)': 'FIXED'
        })
      }, 250)
    })
  },

  async getAccountHistory() {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              date: '11/24/2021',
              description: 'Payment Applied',
              totalAmount: '1,191.40',
              principal: '241.29',
              interest: '739.05'
            },
            {
              date: '11/10/2021',
              description: 'Escrow auto-disbursement',
              totalAmount: '1,191.40',
              principal: '240.16',
              interest: '740.18'
            },
            {
              date: '11/24/2021',
              description: 'Payment Applied',
              totalAmount: '1,191.40',
              principal: '241.29',
              interest: '739.05'
            },
            {
              date: '11/10/2021',
              description: 'Escrow auto-disbursement',
              totalAmount: '1,191.40',
              principal: '240.16',
              interest: '740.18'
            }
          ]),
        250
      )
    })
  },

  async getReports(id) {
    if (id) {
      return new Promise((resolve) => {
        return axios.get(`reports/reportId/${id}/1`).then((r) => {
          setTimeout(() => {
            resolve(r.data)
          }, 50)
        })
      })
    }
    return new Promise((resolve) => {
      return axios.get('reports').then((r) => {
        setTimeout(() => {
          resolve(r.data)
        }, 250)
      })
    })
  },

  async assignToProcessor(orderId) {
    return new Promise((resolve) => {
      return axios.post(`updateorder/${orderId}/InProgress`).then((r) => {
        setTimeout(() => {
          resolve(r.data)
        }, 250)
      })
    })
  },

  async generateReport(orderId) {
    return new Promise((resolve) => {
      return axios.post(`updateorder/${orderId}/Completed`).then((r) => {
        setTimeout(() => {
          resolve(r.data)
        }, 250)
      })
    })
  },

  async getOrders(id) {
    if (id) {
      return new Promise((resolve) => {
        return axios.get(`orders/orderId/${id}/1`).then((r) => {
          setTimeout(() => {
            resolve(r.data)
          }, 250)
        })
      })
    }

    return new Promise((resolve) => {
      return axios.get(`orders`).then((r) => {
        const b = r.data
        b.reverse((x) => x.created)
        setTimeout(() => {
          resolve(b)
        }, 250)
      })
    })
  },

  confirmVendorBankSelection() {},

  // async updateStatus(szStatus) {
  //   // all lower case, separated by hyphen to each word
  //   // szStatus : 'submitted', 'aproved', 'assign-to-processor', 'generate-report'
  //   // axios call to api
  //   // reponse will return the particular item object
  // },

  async submitOrder(payload) {
    // call the api to save the data when user clicks on submit
    // api will generate a new GUID for the order,
    // also send email to the borrower
    // will return the full database again in array format
    const data = JSON.stringify(JSON.stringify(payload))
    return new Promise((resolve) => {
      return axios
        .post('submitorder', data, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then((r) => {
          if (r && r.status === 201) {
            localStorage.setItem('email', r.data)
            resolve()
          }
        })
    })
  }
}
